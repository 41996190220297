<template>
  <BModal
    id="modal-edit"
    ref="modal-edit"
    centered
    size="md"
    :hide-footer="true"
    :hide-header="true"
    no-close-on-backdrop
  >
    <div class=" my-2">
      <div class="text_edit">
        <div class="text-[#333]">
          <b class="text-2xl">Edit Nama Grup Kontak</b>
        </div>
        <p class="text-[#333]">
          Silahkan lengkapi kolom dibawah
        </p>
      </div>
      <div class="form-input-grup-name">
        <label for="input">Nama Folder *</label>
        <b-form-input
          v-model="newName"
          placeholder="Masukkan Nama Folder"
          @input="updateNameGrup"
        />
      </div>
      <BRow>
        <BCol lg="6">
          <b-button
            variant="outline-primary"
            block
            class="mb-1"
            @click="cancelEdit"
          >
            Batal
          </b-button>
        </BCol>
        <BCol lg="6">
          <b-button
            :variant="isDisabled ? 'secondary' : 'primary'"
            block
            class="mb-1"
            :disabled="isDisabled"
            @click="confirmEdit"
          >
            Simpan
          </b-button>
        </BCol>
      </BRow>
    </div>
  </BModal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ModalEdit',
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
    name: {
      type: String,
      default: () => '',
    },
    getAllKontak: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      newName: '',
    }
  },
  computed: {
    isDisabled() {
      return (
        this.newName === this.name || this.newName.trim() === ''
      )
    },
  },
  watch: {
    name(newValue) {
      this.newName = newValue
    },
  },
  methods: {
    cancelEdit() {
      this.$bvModal.hide('modal-edit')
      this.newName = this.name
    },
    async confirmEdit() {
      this.$bvModal.hide('modal-edit')
      const params = {
        name: this.newName,
      }
      await this.$http_new.put(`/komchat-api/api/v1/group-contact/update/${this.id}`, params)
        .then(res => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Sukses',
                icon: 'CheckIcon',
                text: 'Data berhasil diubah!',
                variant: 'success',
              },
            },
            2000,
          )
          this.getAllKontak()
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Data gagal diubah!',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    updateNameGrup() {
      // this.isDisabled = this.newName.trim() === ''
    },
  },
}
</script>

<style scoped>
.form-input-grup-name {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--Grayscale-Line, #E2E2E2);
    margin-bottom: 24px
}
</style>
