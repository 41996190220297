<template>
  <BModal
    id="modal-create"
    ref="modal-create"
    centered
    size="md"
    :hide-footer="true"
    :hide-header="true"
    no-close-on-backdrop
  >
    <div class=" my-2">
      <div class="">
        <div class="text-[#333]">
          <b class="text-2xl">Buat Grup Kontak</b>
        </div>
        <p class="text-[#333]">
          Silahkan lengkapi kolom dibawah
        </p>
      </div>
      <div class="form-input-grup-name">
        <label for="input">Nama Folder *</label>
        <b-form-input
          v-model="textGrup"
          placeholder="Masukkan Nama Folder"
        />
      </div>
      <BRow>
        <BCol lg="6">
          <b-button
            variant="outline-primary"
            block
            class="mb-1"
            @click="cancelCreate"
          >
            Batal
          </b-button>
        </BCol>
        <BCol lg="6">
          <b-button
            :variant="isDisabled ? 'secondary' : 'primary'"
            block
            class="mb-1"
            :disabled="isDisabled"
            @click="confirmCreate"
          >
            Simpan
          </b-button>
        </BCol>
      </BRow>
    </div>
  </BModal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ModalCreate',
  props: {
    getAllKontak: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      textGrup: '',
    }
  },
  computed: {
    isDisabled() {
      return this.textGrup.trim() === ''
    },
  },
  watch: {
    name(newValue) {
      this.textGrup = newValue
    },
  },
  methods: {
    cancelCreate() {
      this.textGrup = ''
      this.$bvModal.hide('modal-create')
    },
    async confirmCreate() {
      this.$bvModal.hide('modal-create')
      const params = {
        name: this.textGrup,
      }
      await this.$http_new.post('/komchat-api/api/v1/group-contact/create', params)
        .then(res => {
          this.textGrup = ''
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Sukses',
                icon: 'CheckIcon',
                text: 'Data berhasil ditambahkan!',
                variant: 'success',
              },
            },
            2000,
          )
          this.getAllKontak()
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Data gagal ditambahkan!',
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
  },
}
</script>

  <style scoped>
  .form-input-grup-name {
      padding: 12px;
      border-radius: 8px;
      border: 1px solid var(--Grayscale-Line, #E2E2E2);
      margin-bottom: 24px
  }
  </style>
